import { SkillDef, SpecRef, CountSpecRef, SkillScoreAward, SkillDefDTO } from ".";

import { isNumber } from "../../../shared/utils/general";


const units: string[] = [
  "hp", "hp,frc", "hp,prc", "hp,sls", "hp,elec", "hp,fire", "hp,cold", "hp,heat", "hp,corr", "hp,psn", "hp,rad", "ep", "fp",
  "metre", "range",
  "adapt,grav", "adapt,press", "adapt,heat", "adapt,cold", "adapt,drought",
  "resist,frc", "resist,prc", "resist,sls", "resist,elec", "resist,fire", "resist,cold", "resist,heat", "resist,corr", "resist,psn", "resist,rad",
  "lightreception"
]

const perUnits: string[] = [
  "level"
]

const antiUnits: string[] = units.map(u => u.replace(',', '%'));

const aspects: string[] = [
  'Biochem-Ammonia', 'Biochem-Chlorine', 'Biochem-Methane', 'Biochem-Sulphur', 'Biochem-Phosphorus', 'Biochem-Water', 
  'Biology-Cephene', 'Biology-Ronite', 'Biology-Freen', 'Biology-Terran',' Biology-Thot', 
  'Env-Artifact', 'Env-Ethereal', 'Env-Space', 'Env-Urban', 'Env-Wilderness', 
  'Tech-Modern', 'Tech-Clavian', 'Tech-Anc-Ronite', 'Tech-Anc-Terran', 'Tech-Cephene', 'Tech-Thotian'
]
const antiAspects: string[] = aspects.map(u => u.replace(',', '%'));

const skillWildcards: string[] = [
  'Any', 'Eng-Any', 'Psi-Any', 'WpnEng-Any', 'Vcl-Any'
]

export interface PropRef {
  /**
   * usually a "+" or "-"
   */
  op?: string

  /**
   * a simple number
   */
  num?: number

  /**
   * a number range, such as of err... ranges like 80/360(range)
   */
  nums?: number[]

  /**
   * 
   */
  per?: string

  ref?: string
  unit?: string
}

const UNIT_MATCH_REX = new RegExp(`(${units.join('|')})`, `g`);
const ANTIUNIT_MATCH_REX = new RegExp(`(${antiUnits.join('|')})`, `g`);

const ASPECT_MATCH_REX = new RegExp(`(\\[${aspects.join('|')}\\])`, `g`);
const ANTIASPECT_MATCH_REX = new RegExp(`(\\[${antiAspects.join('|')}\\])`, `g`);

/**
 * to match references like +1(Str) to +3(Str:Save) or -1(Int/Wis/Cha:Focus), or 4(Con:Health,Int/Wis:Energy,Con:Save,Per:Save,Str/Dex:Cbt-Melee,Dex:Cbt-Ranged)
 */
const SKILL_MOD_MATCH_REX = new RegExp(/([+-]?)([0-9]{1,2})\(([^%]*)\)/);

/**
 * to match references like: "3(rank) for Int:Psiportation:Blink"
 */
const OTHER_PROP_PER_UNIT_MATCH_REX = new RegExp(`([+-]?)([\\.0-9]{1,5})\\/(${perUnits.join('|')})\\((${antiUnits.join('|')})\\)`);

/**
 * to match general properties such as 3/level(hp), 10(metre), 0.5(adapt,grav), 200(lightreception), i.e., where the units are a standard value
 */
const OTHER_PROP_MATCH_REX = new RegExp(`([+-]?)([\\.0-9]{1,5})\\((${antiUnits.join('|')})\\)`);

/**
 * @deprecated to match references like: "3(rank) for Int:Psiportation:Blink"
 */
const SKILL_RANK_AWARD_MATCH_REX = new RegExp(/([0-9]{1})\(rank\)\sfor\s(.*)/);

const SKILL_WILDCARD_MATCH_REX = new RegExp(`(\\[${skillWildcards.join('|')}\\])`, `g`);

//const SKILL_LISTING = new RegExp(/n/);
export class PropDTO {
  /**
   * Reads a string based property preference and returns it as one or more typed values.
   * 
   * String property references present in a variety of forms and are used to provide instructions on the value contained.
   * 
   * A blink dogs list of properties provides some examples of format:
   * 
   *    {"name": "startingAge", "val": "15"},
   *    {"name": "lifespan", "val": "150"},
   *    {"name": "abilityModifiers", "val": "+2(Str),+1(Dex),+1(Con),+1(Per)"},
   *    {"name": "adaptions", "val": "0.5(adapt,grav),0.5(adapt,press)"},
   *    {"name": "speed", "val": "10(metre)"},
   *    {"name": "moves", "val": "natural(walk),unnatural(crawl),unnatural(climb),unnatural(swim)"},
   *    {"name": "senses", "val": "200(lightreception)"},
   *    {"name": "startingFortes", "val": "1(rank) for Int:Psiportation"},
   *    {"name": "startingFineFortes", "val": "3(rank) for Int:Psiportation:Blink"},
   *    {"name": "healthAdvancement", "val": "3/level(hp)"},
   *    {"name": "energyAdvancement", "val": "2/level(ep)"},
   *    {"name": "focusAdvancement", "val": "2/level(ep)"},
   *    {"name": "qualities", "val": "medium,water-alanine,terran,pack instincts,die hard,olfactors"}
   * 
   *  included in the professions are comma-separated skills, such as Int:Investigation:Tracking
   *
   * The skill-based references can be quite complicated, beyond abilities like: +1(Str) to +3(Str:Save) or -1(Int/Wis/Cha:Focus)
   * The first two above are also in modifier form, while the third may be modifier, depending on the context
   * 
   * @param ref
   * @param isModifier - context parameter indicating whether the num return is to be considered a modifier or an absolute
   * (defaults to absolute)
   */
  public static asPropRef = (ref: string): PropRef[] => {

    // the first thing this needs to do is to find all of the units (esp. the ones with commas) and replace them with % (because that is never used anywhere)
    // this will allow us to break the string up into comma-separated groups and assess each
    ref = PropDTO.percentSeperateUnits(ref);
    ref = PropDTO.percentSeperateAspects(ref);

    // lets see if we're dealing with a property array
    let refArray = ref.split(',');
    let propArray: PropRef[] = new Array<PropRef>();
    let arrlen = propArray.length
    refArray?.forEach(r => {
      
//      console.log(`1 arrlen: ${arrlen}, propArray.length: ${propArray.length}`)
      propArray = PropDTO.processIfRankAward(r, propArray);
//      console.log(`2: ${arrlen}, propArray.length: ${propArray.length}`)
      if ( propArray.length === arrlen ) propArray = PropDTO.processIfSkillMod(r, propArray);
//      console.log(`3: ${arrlen}, propArray.length: ${propArray.length}`)
      if ( propArray.length === arrlen ) propArray = PropDTO.processIfGeneralPropPerUnit(r, propArray);
//     console.log(`4: ${arrlen}, propArray.length: ${propArray.length}`)
      if ( propArray.length === arrlen ) propArray = PropDTO.processIfGeneralProp(r, propArray);
//     console.log(`5: ${arrlen}, propArray.length: ${propArray.length}`)

      // its probably just a single value
      if ( propArray.length === arrlen ) propArray = PropDTO.processAsSingleValue(r, propArray);
//      console.log(`6: ${arrlen}, propArray.length: ${propArray.length}`)
      arrlen = propArray.length
//      console.log(propArray)
    });

    // one final thing before we go, its possible that we could have repeats, as in:
    // 2(Per:Clairsentience),1(Cha:Telepathy),1(Int:Psiportation),2(Per:Clairsentience),1(Cha:Telepathy),1(Int:Psiportation)
    // when this happens we need to sum all the props that have the same ref
    console.log(propArray)
/*
    // but don't count yourself! its got to be one *other* one.
    let outputPropArray: PropRef[] = new Array<PropRef>();
    propArray.forEach(pr => {
      let outputPR = outputPropArray.filter(opr => opr.ref === pr.ref);
      console.log('number for out: ' + pr.num)


      if (outputPR.length === 1 && outputPR[0].num && pr.num) {
        // output already has this PropRef its got a num and our currnt has a num
        // so add them together
        let combinedNum = outputPR[0].num as number + pr.num;
        outputPropArray = [...outputPropArray.filter(opr => opr.ref !== pr.ref), {num: combinedNum, ref: outputPR[0].ref }]
      } else if (outputPR.length === 0) {
        // push if output does not have this PropRef
        outputPropArray.push(pr);
      }
      
    })
    console.log(outputPropArray)
    return outputPropArray
    */
    return propArray;

  }

  /**
   * find all of the units (esp. the ones with commas) and replace them with % (because that is never used anywhere)
   * this will allow us to break the ref up into comma-separated groups and assess each
   * "adapt,grav", "adapt,press", "adapt,heat"
   * @param ref 
   * @returns 
   */
  private static percentSeperateUnits = (ref: string): string => {
    const unitsMatches = ref.match(UNIT_MATCH_REX);

    unitsMatches?.forEach(m => {
      let rep: string = m.replace(",", "%")
      ref = ref.replace(m, rep)
    })
    return ref;
  }

  /**
   * Finds an aspect block, such as the items enclosed in the square brackets...
   * Int:Investigation:Tracking[Env-Bio-Urban,Env-Bio-Wilderness,Env-Vcl-Ground,Env-Vcl-Space]
   * and substitutes the commas between them with %
   * 
   * @param ref a ref suh as "Cha:Deception:Blend-In,Cha:Deception:Disguise,Cha:Deception:Feint,Cha:Deception:Sleight-Of-Hand,
   *                          Int:Investigation:Tracking[Env-Bio-Urban,Env-Bio-Wilderness],Cha:Persuasion:Favour,Cha:Persuasion:Inspire,Cha:Persuasion:Rally,Cha:Persuasion:Spoil,
   *                          Wis:Society:Bureaucracy,Wis:Society:Finance,Wis:Society:Law,Dex:Cbt-Melee:Dodge-Attack,Dex:Cbt-Melee:First-Strike,
   *                          Str/Dex:Cbt-Melee:Grapple,Dex:Cbt-Melee:Multi-Attack,Str/Dex:Cbt-Melee:Overbear,Dex:Cbt-Melee:Skirmish,Str/Dex:Cbt-Melee:Vital-Harm,
   *                          Str/Dex:Cbt-Melee:Wpn-Any,Dex:Cbt-Ranged:Dodge-Attack,Dex:Cbt-Ranged:Dodge-Effect,Dex:Cbt-Ranged:First-Strike,Dex:Cbt-Ranged:Group-Fire,
   *                          Dex:Cbt-Ranged:Multi-Attack,Dex:Cbt-Ranged:Retarget,Dex:Cbt-Ranged:Skirmish,Dex:Cbt-Ranged:Sniper,Dex:Cbt-Ranged:Vital-Harm,Dex:Cbt-Ranged:Wpn-Bows,
   *                          Dex:Cbt-Ranged:Wpn-Crossbows,Dex:Cbt-Ranged:Wpn-Pistols,Dex:Cbt-Ranged:Wpn-Thrown,Int:Machinery[Tech-Modern,Tech-Clavian,Tech-Anc-Ronite,Tech-Anc-Terran,Tech-Cephene,Tech-Thotian]"
   *
   * @returns the ref with the commas between the aspects substituted with %
   */
  private static percentSeperateAspects = (ref: string): string => {
    const aspectsMatches = ref.match(ASPECT_MATCH_REX);
    aspectsMatches?.forEach(m => {
      let rep: string = m.replace(",", "%")
      ref = ref.replace(m, rep)
    })
    return ref;
  }

  private static processIfRankAward = (ref: string, propArray: PropRef[]): PropRef[] => {
    console.log('processIfRankAward')
    const skillRankAwardMatch = ref.match(SKILL_RANK_AWARD_MATCH_REX);

    if (skillRankAwardMatch && skillRankAwardMatch.length > 0) {
        // imagine that wildcards will fall through as ref: F-Any, FF-Any etc
        propArray.push( { op: '+', num: parseInt(skillRankAwardMatch?.at(1) || '0'), ref: skillRankAwardMatch?.at(2), unit: 'count' } as PropRef);
    }
    return propArray;
  }

  private static processIfSkillMod = (ref: string, propArray: PropRef[]): PropRef[] => {
    console.log('processIfSkillMod')
    const skillMatches = ref.match(SKILL_MOD_MATCH_REX);

    if (skillMatches && skillMatches.length > 0) {
    /*
      [
        '+1(Str)',
        '+',
        '1',
        'Str',
        index: 0,
        input: '+1(Str)',
        groups: undefined
      ]
    */
      let op = skillMatches?.at(1) ? skillMatches?.at(1) : '+'; // missing a sign is taken as an add operation
      let unit = skillMatches?.at(1) ? 'offset' : 'count';
      propArray.push( { op: op, num: parseInt(skillMatches?.at(2) || '0'), ref: skillMatches?.at(3), unit: unit } as PropRef);
  
    }
    return propArray;
  }

  private static processIfGeneralPropPerUnit = (ref: string, propArray: PropRef[]): PropRef[] => {
    console.log('processIfGeneralPropPerUnit')
    const otherPerMatches: RegExpMatchArray | null = ref.match(OTHER_PROP_PER_UNIT_MATCH_REX);
    if (otherPerMatches && otherPerMatches.length > 0) {
      console.log(otherPerMatches)
      /*
      [
        '3/level(hp)',
        '',
        '3',
        'level',
        'hp',
        index: 0,
        input: '3/level(hp)',
        groups: undefined
      ]
      */
      let rePatternedRef = otherPerMatches?.at(4)?.replace('%', ',');
      propArray.push( { num: parseInt(otherPerMatches?.at(2) || '0'), per: otherPerMatches?.at(3), unit: rePatternedRef } as PropRef);
    }

    return propArray;
  }

  private static processIfGeneralProp = (ref: string, propArray: PropRef[]): PropRef[] => {
    console.log('processIfGeneralProp')
    const otherMatches: RegExpMatchArray | null = ref.match(OTHER_PROP_MATCH_REX);
    if (otherMatches && otherMatches.length > 0) {
      console.log(otherMatches)
      /*
        [
          '0.5(adapt%press)',
          '',
          '0.5',
          'adapt%press',
          index: 0,
          input: '0.5(adapt%press)',
          groups: undefined
        ]
      */
      // re-pattern
      const rePatternedRef = otherMatches?.at(3)?.replace('%', ',');
      const n = otherMatches?.at(2);

      const capturedPropDef = { num: (isNumber(n) && Number.isInteger(n)) ? parseInt(n as string) : parseFloat(n as string), unit: rePatternedRef } as PropRef;
      console.log(capturedPropDef)
      propArray.push( capturedPropDef );
    }

    return propArray;
  }

  private static processAsSingleValue = (ref: string, propArray: PropRef[]): PropRef[] => {
    console.log('processAsSingleValue')
    let propRef: PropRef
    if (isNumber(ref) && Number.isInteger(ref)) {
      propRef = { num: parseInt(ref) } as PropRef
    } else if (isNumber(ref)) {
      propRef = { num: parseFloat(ref) } as PropRef
    } else {
      propRef = { ref: ref } as PropRef
    }
    propArray.push(propRef);

    return propArray;
  }


  public static asCountSpecRefForSkill = (propRef: PropRef): CountSpecRef | undefined => {
    if (propRef.op && propRef.num) {
      return { offset: (propRef.op === '-') ? - propRef.num : propRef.num, spec: { name: propRef.ref, ref: 'skill-def' } } as CountSpecRef
    } else {
      return { count: propRef.num, spec: { name: propRef.ref, ref: 'skill-def' } } as CountSpecRef
    }
  }

  /**
   * Uses a PropRef that references a skill, combined with a source (such as a sophont, backgound) to make a SkillScoreAward
   * @param propRef a reference to the skill targeted by the produced SkillScoreAward. Note this can include various wildcard references
   *              such as *, Any, Vcl-Any, Eng-Any etc.
   * @param source 
   * @returns 
   */
  public static asSkillScoreAward = (propRef: PropRef, source: CountSpecRef, all: SkillDef[], appliesTo: string): SkillScoreAward[] => {

    let ret: SkillScoreAward[] = new Array<SkillScoreAward>();

    let score = propRef.num;
    if (propRef.op && propRef.num) {
      score = (propRef.op === '-') ? - propRef.num : propRef.num;
    }
    
    // propRef.ref could be Any, Vcl-Any, Eng-Any, a single skill like Dex:Save or a comma-separated list, such as: 
    // 4(Con:Health,Int/Wis:Energy,Con:Save,Per:Save,Str/Dex:Cbt-Melee,Dex:Cbt-Ranged)
    // if its singular, then we are returning a single SkillScoreAward, otherwise many.

    // turn {num: 4, ref:Con:Health,Int/Wis:Energy,Con:Save,Per:Save,Str/Dex:Cbt-Melee,Dex:Cbt-Ranged} into 4(Con:Health,Int/Wis:Energy,Con:Save,Per:Save,Str/Dex:Cbt-Melee,Dex:Cbt-Ranged)
    let numberedPropRef = `${propRef.num}(${propRef.ref})`

    // sending off the ref to get split (as needed)
    const skillSpecRefs = SkillDefDTO.anyRefAsSpecRefs(propRef.ref as string, all);
    console.log(skillSpecRefs)

    //todo need to split it out again - this is not done yet, I think its still all bunched up

    skillSpecRefs.forEach(ssr => {
      ret.push({ score: score, awardableScore: score, awardedScore: 0, skill: ssr, source: source, appliesTo: appliesTo } as SkillScoreAward)
    })

    return ret;
  }

  public static asReversingSkillScoreAward = (propRef: PropRef, source: CountSpecRef, all: SkillDef[], appliesTo: string): SkillScoreAward[] => {
    let ret: SkillScoreAward[] = new Array<SkillScoreAward>();

    let score = (propRef.num) ? -propRef.num: 0;
    if (propRef.op && propRef.num) {
      score = (propRef.op === '-') ? - propRef.num : propRef.num;
    }
    let numberedPropRef = `${propRef.num}(${propRef.ref})`
    const skillSpecRefs = SkillDefDTO.anyRefAsSpecRefs(numberedPropRef as string, all);

    skillSpecRefs.forEach(ssr => {
      ret.push({ score: score, awardableScore: score, awardedScore: 0, skill: ssr, source: source, appliesTo: appliesTo } as SkillScoreAward)
    })

    return ret;

  }



  public static asSpecRefsForQuality = (propRefs: PropRef[]): SpecRef[] | undefined => {
    return propRefs.filter(pr => pr.ref !== undefined).map(pr => {return {name: pr.ref, ref:'quality'} as SpecRef});
  }

  /**
   * Returns the PropRef as a simple numeric value.
   * Method assumes that the number is read as is from the num property, unless there is a supplied op value and its value is "-" in which case
   * it returns the negative value of num.
   * @param propRef 
   * @returns 
   */
  public static asNumber = (propRef: PropRef): number | undefined => {
    if (propRef.op && propRef.num) {
      return (propRef.op === '+') ? propRef.num : - propRef.num
    } else {
      return propRef.num
    }

  }

  /**
   * Returns the PropRef as a simple string value.
   * Method assumes that the relevant value for the PropRef will be stored in its ref property
   * @param propRef 
   * @returns 
   */
  public static asString = (propRef: PropRef): string | undefined => {
    if (propRef.ref) {
      return propRef.ref
    }
  }
}