import React from 'react';
import { Wrapper } from '../../../assets/wrappers/Home'
import rulebook from '../../../assets/images/intro/striker_core_rulebook.png';

export const Home = () => {

    return (
        <Wrapper>
            <div className='content'>
            <h2>Roleplay in Space</h2>
            <p>Striker is a science-fiction role-playing game that can be played in the style of a space opera, or fit within
                any noir-style cyberpunk-ish adventure.
            </p>

            <p>The Core Rulebook is in its second edition</p>

            <img src={rulebook} alt='striker core rulebook' width='500' height='331' />

            <p><a href="https://www.lulu.com/shop/michael-coxon/striker-core-rulebook-2nd-edition/paperback/product-v84rdqj.html">Striker Core Rulebook 2nd Edition (paperback)</a> AUD 70 / USD 60 (Lulu)</p>
            <p><a href="https://www.drivethrurpg.com/en/product/483576/Striker-Core-Rulebook-2nd-Edition">Striker Core Rulebook 2nd Edition (downloadable PDF)</a> AUD 20 / USD 15 (DriveThru RPG)</p>
            <p>If you want a preview the complete <a href="https://www.drivethrurpg.com/en/product/460945/striker-core-rulebook">first edition is available at DriveThru RPG</a> for FREE</p>

            <p>The Core Rulebook is completely self-contained, you need no other source books whatsoever. It's 420 pages of rules goodness for making, enhancing and equipping your character and playing the game. 
                It introduces the Striker universe, its technology and politics.</p>

            <p><a href="/Future">I am working on adventures and supplimentary rulebooks. These will be published as I complete them.</a>.</p>
            <p>For updates and more information, follow this invite onto my Discord server: <a href="https://discord.gg/VmB3hH5tTu">https://discord.gg/VmB3hH5tTu</a></p>

            <h4>Free Character Sheets</h4>
            <p><a href="https://docs.google.com/spreadsheets/d/1nYSooUbzD5qApQ9ULrPmMgNR6CBQvbOO3GANTXZU-JU">Character Generator (Google Sheet)</a></p>
            <p><a href="https://drive.google.com/file/d/1Gr_B_tiBfxMLIaDFxHF2y8bVoxYmolpC/view">Printable PDF</a></p>
            <p>If you've bought <a href="https://www.drivethrurpg.com/en/product/468152/striker-book-of-one-shots">The Book of One Shots</a>, 
            and you want to make use of Second Edition characters, or if you just want to have a look at some example Striker characters, enjoy the </p>
           <p><a href="https://drive.google.com/drive/folders/1koNG0hGb5AyXAFQ-kyl-D3e6_NKE6i8Z?usp=sharing"><b>8 One-Shot Characters</b></a>. </p>
            <p>
            Each character is presented in two forms:
            <ol>
            <li>PDF (as they would appear in the Book of One Shots); and</li>
            <li>Google Sheet (used to create then).</li>
            </ol>
            </p>
            
            <h4>The Striker Mechanic</h4>
            <p>Striker uses an innovative <a href="/Mechanic">skills-based d20 mechanic</a>. It scales, hardly needs a single lookup table, involves simple maths, easy to remember, 
            and it tells a good sci fi story (even computer hacking makes sense, no really).</p>


            <h4>Other Freebies</h4>
            <p><a href="https://drive.google.com/drive/folders/1G_3QaiFBauCA5xke-E72HPZrefEa7kMl?usp=drive_link">Game Reference Sheets</a></p>

            </div>
        </Wrapper>
    )
}